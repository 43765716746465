import React from "react";

const Notification = ({ message, type = "success", onClose }) => {
  const colors = {
    success: "bg-green-100 text-green-800 border-green-400",
    error: "bg-red-100 text-red-800 border-red-400",
    warning: "bg-yellow-100 text-yellow-800 border-yellow-400",
    info: "bg-blue-100 text-blue-800 border-blue-400",
  };

  return (
    <div
      className={`flex items-center justify-between p-4 mb-4 w-80 rounded-lg border shadow-lg ${colors[type]} z-[9999]`}
    >
      <span>{message}</span>
      <button
        onClick={onClose}
        className="ml-4 text-gray-700 hover:text-black focus:outline-none"
      >
        ✕
      </button>
    </div>
  );
};

export default Notification;
