import { useState } from "react"

const Header = () => {  

    const [isOpen, setIsOpen] = useState(false)

    const navLinks = []

    return (
        <div id="header" style={{ zIndex: 1001}} className="flex h-16 w-full justify-start ml-4">
            <div id="header_logo" className="flex justify-center items-center space-x-4 py-4">
                <img src={'/logo.jpeg'} alt="Icono de GeoSensores" style={{width: 40, height:40}}/>
                <h1 className="text-xl font-semibold">GeoSensores - Admin</h1>
            </div>
        </div>
    )
}

export default  Header