import { useState } from "react";
import { useBeacons } from "../context/BeaconContext"; // Importar el contexto

const SidebarNewBeacon = ({ isOpen }) => {
    const { addBeacon } = useBeacons(); // Obtener la función del contexto
    const [tooltipVisible, setTooltipVisible] = useState(null);
    const [formData, setFormData] = useState({
        uuid: "",
        brand: "",
        model: "",
        acquisition_date: "",
        activation_date: "",
        battery: "",
        scope: "",
        latitud: "",
        longitud: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleLocationClick = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setFormData({
                        ...formData,
                        latitud: position.coords.latitude.toString(),
                        longitud: position.coords.longitude.toString(),
                    });
                },
                (error) => {
                    console.error("Error obteniendo la ubicación: ", error);
                }
            );
        } else {
            console.error("La geolocalización no es soportada por este navegador.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validar que todos los campos estén completos
        const requiredFields = [
            "uuid",
            "brand",
            "model",
            "acquisition_date",
            "activation_date",
            "scope",
            "latitud",
            "longitud",
        ];
        const areFieldsComplete = requiredFields.every((field) => formData[field]);
        if (!areFieldsComplete) {
            return;
        }

        try {
            // Llamar a la API para añadir el beacon
            await addBeacon({
                uuid: formData.uuid,
                brand: formData.brand,
                model: formData.model,
                acquisition_date: formData.acquisition_date,
                activation_date: formData.activation_date,
                battery: formData.battery,
                scope: parseFloat(formData.scope),
                geometry: {
                    type: "Point",
                    coordinates: [parseFloat(formData.longitud), parseFloat(formData.latitud)],
                },
            });

            // Limpiar el formulario
            setFormData({
                uuid: "",
                brand: "",
                model: "",
                acquisition_date: "",
                activation_date: "",
                battery: "",
                scope: "",
                latitud: "",
                longitud: "",
            });
        } catch (err) {
            console.error("Error al añadir el beacon:", err);
        }
    };

    return (
        <div
            className={`mr-20 fixed top-16 left-0 h-screen bg-white shadow-lg z-50 transition-transform duration-300 ease-in-out transform ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}
            style={{ zIndex: 1000 }} // Ajusta el ancho a un tamaño fijo
        >
            {/* Título fijo */}
            <div id="sidebar" className="bg-white h-full w-full sm:w-96 md:w-80 lg:w-96 shadow-lg flex flex-col">
                <h2 className="text-xl text-center font-semibold p-4 bg-white shadow-md z-10">
                    Añadir nuevo Dispositivo
                    </h2>

                {/* Contenido con scroll */}
                <div className="overflow-y-auto flex-1 p-4 mb-12">
                    <form onSubmit={handleSubmit}>

                        {/* UUID */}
                        <div className="flex items-center justify-between">
                            <label className="block mb-1 mr-2">UUID</label>
                            <button
                                type="button"
                                onClick={() =>
                                    setTooltipVisible(tooltipVisible === "uuid" ? null : "uuid")
                                }
                                className="relative w-5 h-5 flex mb-1 items-center justify-center bg-green-800 rounded-full focus:outline-none text-white"
                            >
                                ?
                                {tooltipVisible === "uuid" && (
                                    <div className="z-50 absolute transform -translate-x-1/2 mr-6 px-3 py-2 bg-green-800 text-white text-sm rounded shadow-lg whitespace-nowrap">
                                    Más información{" "}
                                    <a
                                        href="https://docs.google.com/document/d/1xPgsuFwA_yYIkMEgF8zaSYD2v2txDY5_/edit?usp=sharing&ouid=102929127326246621182&rtpof=true&sd=true"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="underline hover:text-gray-300"
                                    >
                                        aquí
                                    </a>.
                                </div>
                                )}
                            </button>
                        </div>
                        {/* <label className="block mb-1">UUID</label> */}
                        <input
                            type="text"
                            name="uuid"
                            value={formData.uuid}
                            onChange={handleInputChange}
                            placeholder="UUID"
                            className="w-full mb-2 p-2 border rounded"
                        />

                        {/* Marca */}
                        <label className="block mb-1">Marca</label>
                        <input
                            type="text"
                            name="brand"
                            value={formData.brand}
                            onChange={handleInputChange}
                            placeholder="Marca"
                            className="w-full mb-2 p-2 border rounded"
                        />

                        {/* Modelo */}
                        <label className="block mb-1">Modelo</label>
                        <input
                            type="text"
                            name="model"
                            value={formData.model}
                            onChange={handleInputChange}
                            placeholder="Modelo"
                            className="w-full mb-2 p-2 border rounded"
                        />

                        {/* Fecha de adquisición */}
                        <div className="flex items-center justify-between">
                            <label className="block mb-1 mr-2">Fecha de adquisición</label>
                            <button
                                type="button"
                                onClick={() =>
                                    setTooltipVisible(tooltipVisible === "fecha_1" ? null : "fecha_1")
                                }
                                className="relative w-5 h-5 flex mb-1 items-center justify-center bg-green-800 rounded-full focus:outline-none text-white"
                            >
                                ?
                                {tooltipVisible === "fecha_1" && (
                                    <div className="z-50 absolute transform -translate-x-1/2 mr-6 px-3 py-2 bg-green-800 text-white text-sm rounded shadow-lg whitespace-nowrap">
                                        Es la fecha de compra del dispositivo.
                                    </div>
                                )}
                            </button>
                        </div>
                        <input
                            type="date"
                            name="acquisition_date"
                            value={formData.acquisition_date}
                            onChange={handleInputChange}
                            className="w-full mb-2 p-2 border rounded"
                        />

                        {/* Fecha de activación */}
                        <div className="flex items-center justify-between">
                            <label className="block mb-1 mr-2">Fecha de activación</label>
                            <button
                                type="button"
                                onClick={() =>
                                    setTooltipVisible(tooltipVisible === "fecha_2" ? null : "fecha_2")
                                }
                                className="relative w-5 h-5 flex mb-1 items-center justify-center bg-green-800 rounded-full focus:outline-none text-white"
                            >
                                ?
                                {tooltipVisible === "fecha_2" && (
                                    <div className="z-50 absolute transform -translate-x-1/2 mr-6 px-3 py-2 bg-green-800 text-white text-sm rounded shadow-lg whitespace-nowrap">
                                        Es la fecha del último cambio de baterías.
                                    </div>
                                )}
                            </button>
                        </div>
                        <input
                            type="date"
                            name="activation_date"
                            value={formData.activation_date}
                            onChange={handleInputChange}
                            className="w-full mb-2 p-2 border rounded"
                        />
                        {/* Battery */}
                        <label className="block mb-1">Duración de Bateria (dias)</label>
                        <input
                            type="number"
                            name="battery"
                            value={formData.battery}
                            onChange={handleInputChange}
                            placeholder="365"
                            className="w-full mb-2 p-2 border rounded"
                        />

                        {/* Alcance */}
                        <label className="block mb-1">Alcance (m)</label>
                        <input
                            type="number"
                            name="scope"
                            value={formData.scope}
                            onChange={handleInputChange}
                            placeholder="20"
                            className="w-full mb-2 p-2 border rounded"
                        />

                        {/* Latitud y Longitud */}
                        <div className="flex space-x-4 mb-4">
                            <div className="w-1/2">
                                <label className="block mb-1">Latitud</label>
                                <input
                                    type="number"
                                    name="latitud"
                                    value={formData.latitud}
                                    onChange={handleInputChange}
                                    placeholder="Latitud"
                                    className="w-full p-2 border rounded"
                                    step="0.000001"
                                    min="-90"
                                    max="90"
                                />
                            </div>
                            <div className="w-1/2">
                                <label className="block mb-1">Longitud</label>
                                <input
                                    type="number"
                                    name="longitud"
                                    value={formData.longitud}
                                    onChange={handleInputChange}
                                    placeholder="Longitud"
                                    className="w-full p-2 border rounded"
                                    step="0.000001"
                                    min="-180"
                                    max="180"
                                />
                            </div>
                        </div>

                        <button
                            type="button"
                            onClick={handleLocationClick}
                            className="w-full mb-4 bg-gray-500 text-white py-2 rounded"
                        >
                            Obtener Ubicación Actual
                        </button>

                        {/* Botón de enviar */}
                        <button
                            type="submit"
                            className="w-full mb-8 bg-blue-500 text-white py-2 rounded"
                        >
                            Añadir
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SidebarNewBeacon;