import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNotification } from "../hooks/useNotification"

// Crear el contexto
const BeaconContext = createContext();

// Proveedor del contexto
export const BeaconProvider = ({ children }) => {
    const { showNotification, NotificationComponent } = useNotification();
    const [beacons, setBeacons] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL; // URL base de la API

    // Obtener la lista de beacons
    const fetchBeacons = async () => {
        try {
            const response = await fetch(`${apiUrl}/beacons`);
            if (!response.ok) {
                throw new Error('Error al obtener los datos de la API');
            }
            const data = await response.json();
            setBeacons(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Modificar un beacon
    const updateBeacon = async (id, updatedData) => {
        try {
            const response = await fetch(`${apiUrl}/beacon/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedData),
            });
            if (!response.ok) {
                throw new Error('Error al actualizar el beacon');
            }
            showNotification("Beacon actualizado exitosamente", "success");
            const updatedBeacon = await response.json();
            setBeacons((prevBeacons) =>
                prevBeacons.map((beacon) => (beacon._id === id ? updatedBeacon : beacon))
            );
        } catch (error) {
            console.error('Error:', error);
            showNotification("No se ha podido modificar el beacon", "error");
        }
    };

    // Eliminar un beacon
    const deleteBeacon = async (id) => {
        try {
            const response = await fetch(`${apiUrl}/beacon/${id}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error('Error al eliminar el beacon');
            }
            showNotification("Beacon eliminado exitosamente", "success");
            setBeacons((prevBeacons) =>
                prevBeacons.filter((beacon) => beacon._id !== id)
            );
        } catch (error) {
            console.error('Error:', error);
            showNotification("No se ha podido eliminar el beacon", "error");
        }
    };

    // Agregar un nuevo beacon
    const addBeacon = async (newBeacon) => {
        try {
            const response = await fetch(`${apiUrl}/beacon`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newBeacon),
            });
            if (!response.ok) {
                throw new Error('Error al agregar un nuevo beacon');
            }
            showNotification("Beacon creado exitosamente", "success");
            const createdBeacon = await response.json();
            setBeacons((prevBeacons) => [...prevBeacons, createdBeacon]);
        } catch (error) {
            console.error('Error:', error);
            showNotification("No se ha podido crear el beacon", "error");
        }
    };

    // Ejecutar la consulta al cargar
    useEffect(() => {
        fetchBeacons();
    }, []);

    return (
        <BeaconContext.Provider
            value={{
                beacons,
                fetchBeacons,
                updateBeacon,
                deleteBeacon,
                addBeacon,
            }}
        >
            {children}
            <NotificationComponent />
        </BeaconContext.Provider>
    );
};

// Hook personalizado para usar el contexto
export const useBeacons = () => {
    return useContext(BeaconContext);
};