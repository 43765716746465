import { useState } from "react";
import Notification from "../components/Notification";

export const useNotification = () => {
  const [notifications, setNotifications] = useState([]);

  const showNotification = (message, type = "success") => {
    const id = Date.now(); // ID único para cada notificación
    setNotifications((prev) => [...prev, { id, message, type }]);

    // Eliminar automáticamente después de 5 segundos
    setTimeout(() => {
      setNotifications((prev) => prev.filter((notif) => notif.id !== id));
    }, 5000);
  };

  const removeNotification = (id) => {
    setNotifications((prev) => prev.filter((notif) => notif.id !== id));
  };

  const NotificationComponent = () => (
    <div className="fixed bottom-4 right-4 flex flex-col items-end z-[9999]">
      {notifications.map((notif) => (
        <Notification
          key={notif.id}
          message={notif.message}
          type={notif.type}
          onClose={() => removeNotification(notif.id)}
        />
      ))}
    </div>
  );

  return { showNotification, NotificationComponent };
};
