import { useBeacons } from '../context/BeaconContext';
import EditBeaconPopup from './EditBeaconPopup';
import React, { useState } from 'react';

const SidebarBeacons = ({ isOpen }) => {
    const { beacons, deleteBeacon, updateBeacon } = useBeacons();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedBeacon, setSelectedBeacon] = useState(null);

    // Función para calcular el porcentaje de batería restante
    const calculateBatteryPercentage = (batteryDurationDays, activationDate) => {
        const today = new Date();
        const activation = new Date(activationDate);
        const daysElapsed = Math.floor((today - activation) / (1000 * 60 * 60 * 24)); // Días transcurridos
        const percentage = Math.max(
            0,
            Math.round(((batteryDurationDays - daysElapsed) / batteryDurationDays) * 100)
        ); // Asegura que no sea menor que 0
        return percentage;
    };

    // Función para obtener el color basado en el porcentaje de batería
    const getBatteryColor = (percentage) => {
        // Dividir en 5 rangos y asignar colores específicos
        if (percentage >= 80) {
            return "#99ff99"; // Verde brillante
        } else if (percentage >= 60) {
            return "#eeff99"; // Verde amarillento
        } else if (percentage >= 40) {
            return "#ffee99"; // Amarillo
        } else if (percentage >= 20) {
            return "#ffcc99"; // Naranja
        } else {
            return "#ff9999"; // Rojo
        }
    };



    // Ordenar beacons por batería (de menor a mayor)
    const sortedBeacons = [...beacons].sort((a, b) => {
        const aBattery = calculateBatteryPercentage(a.battery, a.activation_date);
        const bBattery = calculateBatteryPercentage(b.battery, b.activation_date);
        return aBattery - bBattery;
    });

    const handleEditClick = (beacon) => {
        setSelectedBeacon(beacon);
        setIsPopupOpen(true);
    };

    const handlePopupClose = () => {
        setSelectedBeacon(null);
        setIsPopupOpen(false);
    };

    return (
        <div>
            <div
                className={`fixed top-16 left-0 mr-20 h-screen bg-white shadow-lg z-50 transition-transform duration-300 ease-in-out transform ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}
                style={{ zIndex: 1000}}
            >
                <div id="sidebar" className="bg-white w-full sm:w-96 md:w-80 lg:w-96 h-full shadow-lg flex flex-col">
                    {/* Título fijo */}
                    <h2 className="text-xl text-center font-semibold p-4 bg-white shadow-md z-10">
                        Lista de Dispositivos
                    </h2>

                    {/* Contenido con scroll */}
                    <div className="overflow-y-auto flex-1 p-4 mb-12">
                        <ul>
                            {sortedBeacons.map((beacon) => {
                                const batteryPercentage = calculateBatteryPercentage(beacon.battery, beacon.activation_date);
                                const backgroundColor = getBatteryColor(batteryPercentage);

                                return (
                                    <li key={beacon._id} className="mb-4">
                                        <div
                                            className="flex flex-row justify-between p-4 rounded-lg w-full"
                                            style={{ backgroundColor }}
                                        >
                                            <div className="flex flex-col">
                                                <div className="mb-1">
                                                    <span className="text-lg font-semibold">
                                                        {beacon.brand} {beacon.model}
                                                    </span>
                                                </div>
                                                <div className="text-sm text-gray-600 leading-tight">
                                                    <span><strong>UUID:</strong> {beacon.uuid}</span><br />
                                                    <span>
                                                        <strong>Bateria:</strong> {batteryPercentage}%
                                                    </span><br />
                                                    <span><strong>Alcance:</strong> {beacon.scope} mts</span><br />
                                                    <span>
                                                        <strong>Ubicación:</strong> [
                                                        {beacon.geometry.coordinates[0]},{" "}
                                                        {beacon.geometry.coordinates[1]}
                                                        ]
                                                    </span><br />
                                                </div>
                                            </div>
                                            <div
                                                id="botones"
                                                className="flex flex-col space-y-2 justify-center"
                                            >
                                                {/* Botón verde */}
                                                <button
                                                    className="bg-green-500 text-white p-2 rounded"
                                                    onClick={() => handleEditClick(beacon)}
                                                >
                                                    ✏️
                                                </button>
                                                {/* Botón rojo */}
                                                <button
                                                    className="bg-red-500 text-white p-2 rounded"
                                                    onClick={() => {
                                                        if (window.confirm('¿Estás seguro de que deseas eliminar este beacon?')) {
                                                            deleteBeacon(beacon._id);
                                                        }
                                                    }}
                                                >
                                                    🗑️
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            {/* EditBeaconPopup ocupa toda la pantalla */}
            <div className="flex-1">
                <EditBeaconPopup
                    beacon={selectedBeacon}
                    isOpen={isPopupOpen}
                    onClose={handlePopupClose}
                    onSave={(updatedBeacon) =>
                        updateBeacon(updatedBeacon._id, updatedBeacon)
                    }
                />
            </div>
        </div>
    );
};

export default SidebarBeacons;
