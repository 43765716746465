import Body from './components/Body';
import Header from './components/Header'
import './App.css';
import { BeaconProvider } from './context/BeaconContext';

function App() {
  return (
    <BeaconProvider>
      <div id="App" className="h-screen flex flex-col overflow-hidden">
        <Header/>
        {/*<Mapa/>*/}
        <Body/>
      </div>
    </BeaconProvider>
  );
}

export default App;
