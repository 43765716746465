import React from "react";

const EditBeaconPopup = ({ beacon, isOpen, onClose, onSave }) => {
  const [updatedBeacon, setUpdatedBeacon] = React.useState(beacon);

  React.useEffect(() => {
    setUpdatedBeacon(beacon);
  }, [beacon]);

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    setUpdatedBeacon((prev) => {
      if (name === "latitude") {
        return {
          ...prev,
          geometry: {
            ...prev.geometry,
            coordinates: [prev.geometry.coordinates[0], parseFloat(value)],
          },
        };
      } else if (name === "longitude") {
        return {
          ...prev,
          geometry: {
            ...prev.geometry,
            coordinates: [parseFloat(value), prev.geometry.coordinates[1]],
          },
        };
      } else {
        return { ...prev, [name]: value };
      }
    });
  };
  

  const handleSave = () => {
    onSave(updatedBeacon); // Llama a onSave, que debe utilizar updateBeacon
    onClose(); // Cierra el Popup
  };

  const handleCaptureLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUpdatedBeacon((prev) => ({
            ...prev,
            geometry: {
              ...prev.geometry,
              coordinates: [
                position.coords.longitude, // Actualiza el índice 0 con la longitud
                position.coords.latitude,  // Actualiza el índice 1 con la latitud
              ],
            },
          }));
        },
        (error) => {
          alert("No se pudo obtener la ubicación. Asegúrate de habilitar el GPS.");
          console.error(error);
        }
      );
    } else {
      alert("La geolocalización no es compatible con este navegador.");
    }
  };
  

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9999]">
      <div className="bg-white p-6 rounded-lg w-96">
        <h2 className="text-lg text-center font-bold mb-4">Editar Beacon</h2>
        <form className="space-y-4">
          <div>
            <label className="block text-sm font-semibold">UUID</label>
            <input
              type="text"
              name="uuid"
              value={updatedBeacon?.uuid || ''}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold">Marca</label>
            <input
              type="text"
              name="brand"
              value={updatedBeacon?.brand || ''}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold">Modelo</label>
            <input
              type="text"
              name="model"
              value={updatedBeacon?.model || ''}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold">Fecha de adquisición</label>
            <input
              type="date"
              name="acquisition_date"
              value={updatedBeacon?.acquisition_date || ''}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold">Fecha de activación</label>
            <input
              type="date"
              name="activation_date"
              value={updatedBeacon?.activation_date || ''}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold">Duración de baterías (días)</label>
            <input
              type="number"
              name="battery"
              value={updatedBeacon?.battery || ''}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold">Alcance (metros)</label>
            <input
              type="number"
              name="scope"
              value={updatedBeacon?.scope || ''}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold">Ubicación</label>
            <div className="flex items-center space-x-2">
              <input
                type="number"
                name="latitude"
                placeholder="Latitud"
                value={updatedBeacon?.geometry.coordinates[1] || ''}
                onChange={handleChange}
                className="w-1/2 p-2 border rounded"
              />
              <input
                type="number"
                name="longitude"
                placeholder="Longitud"
                value={updatedBeacon?.geometry.coordinates[0] || ''}
                onChange={handleChange}
                className="w-1/2 p-2 border rounded"
              />
              <button
                type="button"
                onClick={handleCaptureLocation}
                className="px-4 py-2 bg-yellow-500 text-white rounded"
                title="Capturar ubicación actual"
              >
                📍
              </button>
            </div>
          </div>
        </form>
        <div className="flex justify-end mt-4 space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-black rounded"
          >
            Cancelar
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditBeaconPopup;
