import { useState } from "react";
import Mapa from './Mapa';
import SidebarNewBeacon from './SidebarNewBeacon';
import SidebarBeacons from './SidebarBeacons';

const Body = () => {
    const [sidebarOpen, setSidebarOpen] = useState(null); // Controla si se abre "NewBeacon" o "BeaconList"
    const toggleMenu = () => {
        setSidebarOpen(sidebarOpen === "NewBeacon" ? null : "NewBeacon");
    };

    const toggleLista = () => {
        setSidebarOpen(sidebarOpen === "BeaconList" ? null : "BeaconList");
    };

    // Cierra cualquier menu lateral.
    const closeSidebar = () => {
        setSidebarOpen(null); 
    };

    return (
        <div id="body" className="relative h-full w-full">
            {/* Mapa ocupa toda la pantalla */}
            <Mapa />

            {/* Botones en la esquina superior derecha del mapa */}
            <div className="absolute top-5 right-5 flex flex-col space-y-2" style={{ zIndex: 1000 }}>

                <button
                    className={`text-white rounded-lg ${sidebarOpen === "NewBeacon" ? 'bg-red-500' : 'bg-green-500'}`}
                    onClick={toggleMenu}
                    style={{ width: '40px', height: '40px' }}
                    title={sidebarOpen === "NewBeacon" ? 'Cerrar' : 'Nuevo Beacon'}
                >
                    {sidebarOpen === "NewBeacon" ? '\u2715' : '\u002B'} {/* Cambia entre cruz y más */}
                </button>

                <button
                    className={`text-white rounded-lg ${sidebarOpen === "BeaconList" ? 'bg-red-500' : 'bg-gray-500'}`}
                    onClick={toggleLista}
                    style={{ width: '40px', height: '40px' }} 
                >
                    {sidebarOpen === "BeaconList" ? '\u2715' : '\u2630' }
                </button>
            </div>

            {/* Si isListaOpen es true, muestra ListaBeacons en el centro de la pantalla */}
            <SidebarBeacons isOpen={sidebarOpen === "BeaconList"} />


            {/* Menú lateral */}
            <SidebarNewBeacon isOpen={sidebarOpen === "NewBeacon"}/>
        </div>
    );
};

export default Body;
