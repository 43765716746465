import L from 'leaflet';
import React, { useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { useBeacons } from '../context/BeaconContext';
import EditBeaconPopup from './EditBeaconPopup';

const BeaconMarker = () => {
  const { beacons, deleteBeacon, updateBeacon } = useBeacons();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedBeacon, setSelectedBeacon] = useState(null);

  const beaconIcon = L.icon({
    iconUrl: './beacon.png',
    iconSize: [64, 64],
    iconAnchor: [32, 64],
    popupAnchor: [0, -32],
  });

  const handleEditClick = (beacon) => {
    setSelectedBeacon(beacon);
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setSelectedBeacon(null);
    setIsPopupOpen(false);
  };

  // Función para calcular el porcentaje de batería restante
  const calculateBatteryPercentage = (batteryDurationDays, activationDate) => {
    const today = new Date();
    const activation = new Date(activationDate);
    const daysElapsed = Math.floor((today - activation) / (1000 * 60 * 60 * 24)); // Días transcurridos
    const percentage = Math.max(
      0,
      Math.round(((batteryDurationDays - daysElapsed) / batteryDurationDays) * 100)
    ); // Asegura que no sea menor que 0
    return percentage;
  };

  const markers = beacons.map((beacon) => {
    // Calcula el porcentaje de batería para cada beacon
    const batteryPercentage = calculateBatteryPercentage(beacon.battery, beacon.activation_date);

    return (
      <Marker
        key={beacon._id}
        position={{
          lat: beacon.geometry.coordinates[1],
          lng: beacon.geometry.coordinates[0],
        }}
        icon={beaconIcon}
      >
        <Popup>
          <div className="flex flex-col p-2 rounded-lg w-full">
            <div className="mb-1">
              <span className="text-lg font-semibold">
                {beacon.brand} {beacon.model}
              </span>
            </div>
            <div className="text-sm text-gray-600 leading-tight">
              <span>
                <strong>UUID:</strong> {beacon.uuid}
              </span>
              <br />
              <span>
                <strong>Batería:</strong> {batteryPercentage}%
              </span>
              <br />
              <span>
                <strong>Alcance:</strong> {beacon.scope} mts
              </span>
              <br />
              <span>
                <strong>Ubicación:</strong> [{beacon.geometry.coordinates[0]},{' '}
                {beacon.geometry.coordinates[1]}]
              </span>
              <br />
            </div>
            <div className="flex justify-center items-center space-x-4 mt-4">
              <button
                className="bg-green-500 text-white p-2 rounded flex items-center justify-center"
                style={{ width: 50, height: 50 }}
                onClick={() => handleEditClick(beacon)} // Abre el Popup con el beacon seleccionado
              >
                ✏️
              </button>
              <button
                className="bg-red-500 text-white p-2 rounded flex items-center justify-center"
                style={{ width: 50, height: 50 }}
                onClick={() => {
                  if (
                    window.confirm(
                      '¿Estás seguro de que deseas eliminar este beacon?'
                    )
                  ) {
                    deleteBeacon(beacon._id);
                  }
                }}
              >
                🗑️
              </button>
            </div>
          </div>
        </Popup>
      </Marker>
    );
  });

  return (
    <>
      {markers}
      <EditBeaconPopup
        beacon={selectedBeacon}
        isOpen={isPopupOpen}
        onClose={handlePopupClose}
        onSave={(updatedBeacon) =>
          updateBeacon(updatedBeacon._id, updatedBeacon)
        }
      />
    </>
  );
};

export default BeaconMarker;
