import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import BeaconMarker from './BeaconMarker';

const Mapa = () => {
    return (
        <div className='h-full w-full'>
            <MapContainer key="map" attributionControl={false} center={[-39.804092305408524, -73.25013058088139]} zoom={17} style={{ height: '100%', width: '100%' }} scrollWheelZoom={true}>
                <TileLayer
                    url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                    maxZoom={18}
                    minZoom={16}
                    id="mapbox/streets-v12" // Asegúrate de cambiar esto por el ID de tu estilo oscuro en Mapbox
                    tileSize={512}
                    zoomOffset={-1}
                    accessToken="pk.eyJ1IjoiYmFzdGlhbjEyMTIiLCJhIjoiY2x1MnphNXc1MHBwdzJsdGdqNGQ4OGN5byJ9.ITbzAjzDNO-bWIfyriY2Pw" // 
                />
                <BeaconMarker />
            </MapContainer>
        </div>
    );
};

export default Mapa;
